import {statisticsEvent, mtaH5Click} from "@reco-w/core-ui";
import {ArticleCatalogueEnum} from "./common";

/**
 * 获取资讯数据目录名称
 * @param catalogueCode  数据目录代码
 * @returns
 */
export function getArticleText(catalogueCode, tagValue?: any) {
    if (tagValue) {
        if (+tagValue === 1) {
            return "党建动态";
        } else if (+tagValue === 2) {
            return "党员风采";
        } else if (+tagValue === 3) {
            return "党建学习";
        }
    }
    switch (catalogueCode) {
        default:
            return "";
        case ArticleCatalogueEnum.article:
            return "动态资讯";
        case ArticleCatalogueEnum.notification:
            return "通知通告";
        case ArticleCatalogueEnum.dangjdt:
            return "党建动态";
        case ArticleCatalogueEnum.dangyfc:
            return "党员风采";
        case ArticleCatalogueEnum.dangjxx:
            return "党建学习";
        case ArticleCatalogueEnum.dangjzx:
            return "党建资讯";
    }
}

/**
 * 获取资讯埋点对应事件
 * @param catalogueCode 数据目录代码，获取埋点事件名前缀
 * @param map 埋点事件名其他部分
 * @returns statisticsEvent
 */
export function getArticleStaticsMap(catalogueCode, map) {
    const str = catalogueCode === ArticleCatalogueEnum.article ? "article" : catalogueCode === ArticleCatalogueEnum.notification ? "notification" : "";
    const eventMap = str + map;
    return statisticsEvent[eventMap];
}

/**
 * 资讯埋点
 * @param catalogueCode 数据目录代码，获取埋点事件名前缀
 * @param map 埋点事件名其他部分
 */
export function articleStatics(catalogueCode, map) {
    const event = getArticleStaticsMap(catalogueCode, map);
    mtaH5Click(event);
}
