import {EffectsMapObject, routerRedux} from "dva";
import {ReducersMapObject} from "redux";
import {freeze} from "immer";

import {CoreEffects, CoreReducers, getLocalStorage, CoreState} from "@reco-m/core";
import {app, getParkCode} from "@reco-w/core-ui";

import {Namespaces as commonNamespaces} from "@reco-w/common-models";
import {parkCateService} from "@reco-w/impression-service";
import {NULL_POINTER} from "@reco-w/common-common";

import {articleService} from "@reco-w/article-service";
import {
    ArticlePageSizeEnum,
    ARTICLE_HOT_LIST_DEFAULT_ORDERBY,
    ARTICLE_LIST_DEFAULT_ORDERBY,
    ARTICLE_ROOT_CODE,
    Namespaces
} from "./common";


export namespace articleDetailModel {
    export const namespace = Namespaces.articleDetail;

    export const state: any = freeze(
        {
            ...CoreState,
            showloading: true,
        },
        !0
    );

    export type StateType = typeof state;

    export const reducers: ReducersMapObject = {
        ...CoreReducers,

        initState() {
            return state;
        },
    };

    export const effects: EffectsMapObject = {
        ...CoreEffects,
        /**
         * 初始化页面
         * @param { message, callback, id, listData }
         * @param { call, put }
         */
        * initPage({message, callback, id, listData}, {call, put}) {
            try {
                yield put({type: "initState", message});
                yield put({type: "getArticleDetail", message, listData, id, callback});
            } catch (e) {
                yield call(message!.error, "initPage" + e.errmsg);
            }
        },
        /**
         * 获取文章详情
         * @param { message, listData, id, callback }
         * @param { call, put }
         */
        * getArticleDetail({message, listData, id, callback}, {call, put}) {
            try {
                const articleDetail = yield call(articleService.get, id);
                let articleVM = articleDetail?.articleVM;
                yield put({type: "handleArticleDetail", articleVM});
                yield put({
                    type: "getHotArticleList",
                    catalogueId: articleVM?.catalogueId,
                    articleTagVMList: articleDetail.articleTagVMList || []
                });
                yield put({
                    type: "getAdjacentArticle",
                    message,
                    curId: id,
                    data: listData,
                    catalogueId: articleVM?.catalogueId,
                    articleTagVMList: articleDetail.articleTagVMList || []
                });

                yield put({
                    type: "input",
                    data: {articleDetail: articleDetail, articleId: id},
                });

                callback && callback(articleVM);
            } catch (e) {
                if (e?.errcode === NULL_POINTER) {
                    yield call(message!.error, "未找到该数据");
                    const articleCatalogs = yield call(parkCateService.getList, {
                        authorized: false,
                        rootCode: ARTICLE_ROOT_CODE
                    });
                    const catalog = articleCatalogs?.length > 0 && articleCatalogs[0];
                    yield put(routerRedux.replace(`/${getParkCode()}/article/${catalog?.catalogCode}`));
                } else {
                    yield call(message!.error, "getArticleDetail：" + e.errmsg);
                }
            }
        },
        /**
         * 处理资讯详情信息，获取catalogueCode
         * @param { message, articleVM }
         * @param { call, put }
         */
        * handleArticleDetail({message, articleVM}, {call, put}) {
            try {
                let catalogueCode;
                let title;
                if (articleVM) {
                    title = articleVM ? articleVM.title + "_" + articleVM.catalogueName : articleVM.title;
                    catalogueCode = articleVM && articleVM.catalogueCode;
                    yield put({
                        type: `${commonNamespaces.cmsCommon}/getHeadJson`,
                        location: "/" + articleVM?.catalogueCode,
                        title,
                        message
                    });
                }

                yield put({
                    type: "input",
                    data: {catalogueCode: catalogueCode},
                });
            } catch (e) {
                yield call(message!.error, "handleArticleDetail：" + e.errmsg);
            }
        },
        /**
         * 获取热门点击的资讯
         * @param { message, catalogueId }
         * @param { call, put }
         */
        * getHotArticleList({message, catalogueId, articleTagVMList}, {call, put}) {
            try {
                const hotArticleList = yield call(articleService.getPaged, {
                    pageSize: ArticlePageSizeEnum.hotArticle,
                    pageIndex: 1,
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_HOT_LIST_DEFAULT_ORDERBY,
                    catalogueId,
                    isPublish: true,
                    tagValue: articleTagVMList.length > 0 && articleTagVMList[0].tagValue
                });

                yield put({type: "input", data: {hotArticleList: hotArticleList.items}});
            } catch (e) {
                console.log(e)
                yield call(message!.error, "getHotArticleList：" + e);
            }
        },
        /**
         * 获取相邻的数据
         * @param { message, data, catalogueId }
         * @param { call, put }
         */
        * getAdjacentArticle({message, data, catalogueId, curId, articleTagVMList}, {call, put}) {
            try {
                yield put({type: "showLoading"});

                let params = {
                    catalogueId, // 动态资讯
                    isPublish: true,
                    parkId: getLocalStorage("parkId"),
                    orderBy: ARTICLE_LIST_DEFAULT_ORDERBY,
                    authorized: false,
                    ...data,
                    pageSize: 1,
                    curId,
                    tagValue: articleTagVMList.length > 0 && articleTagVMList[0].tagValue
                };

                const prePage = yield call(articleService.getPaged, {...params, isPrevious: true});
                const nextPage = yield call(articleService.getPaged, {...params, isNext: true});

                let result: any = {};
                if (prePage?.items?.length > 0) {
                    result.previousData = prePage?.items[0];
                }
                if (nextPage?.items?.length > 0) {
                    result.nextData = nextPage?.items[0];
                }

                yield put({type: "input", data: {adjacentArticle: result}});
            } catch (e) {
                yield call(message!.error, "getAdjacentArticle：" + e.errmsg);
            } finally {
                yield put({type: "hideLoading"});
            }
        },
    };
}

app.model(articleDetailModel);
