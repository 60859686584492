export enum Namespaces {
    article = "articleNew",
    articleDetail = "articleDetailNew",
}

/**
 * 资讯数据目录代码
 */
export enum ArticleCatalogueEnum {
    /**
     * 通知通告
     */
    notification = "TZTG",
    /**
     * 动态资讯
     */
    article = "DTZX",
    dangjdt = "dangjdt",
    dangyfc = "dangyfc",
    dangjxx = "dangjxx",
    dangjzx = "dangjzx"
}

/**
 * 默认pageSize
 */
export enum ArticlePageSizeEnum {
    /**
     * 热门文章
     */
    hotArticle = 5,
    /**
     * 默认文章pageSize
     */
    defaultArticleList = 8,
    /**
     * 文章查询列表pageSize
     */
    articleSearch = 10,
    /**
     * 所有
     */
    all = 999,
}

/**
 * 列表页不显示图片的资讯Code
 */
export const ARTICLE_HIDE_PICTURE_CODE = [ArticleCatalogueEnum.notification];
/**
 * 资讯列表接口默认排序
 */
export const ARTICLE_LIST_DEFAULT_ORDERBY = "isTop desc,sequence asc,publishTime desc";
/**
 * 热门资讯默认排序
 */
export const ARTICLE_HOT_LIST_DEFAULT_ORDERBY = "viewCount desc";
/**
 * 资讯数据目录code
 */
export const ARTICLE_ROOT_CODE = "ARTICLE_PARK";
