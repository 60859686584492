import {HttpService, resolveService} from "@reco-m/core";

export class ParkHttpService extends HttpService {
    protected parkPromise: any;

    constructor() {
        super("organization/park");
    }

    getPaged(data?: any) {
        this.parkPromise || (this.parkPromise = this.httpGet("", this.resolveRequestParams(data)));
        return this.parkPromise || Promise.resolve({});
    }

    list(data: any) {
        return this.httpGet("list", this.resolveRequestParams(data));
    }
}

export const parkService = resolveService(ParkHttpService);

export class SearchTopicHttpService extends HttpService {
    constructor() {
        super("universalsearch");
    }

    getSearchData(data: any) {
        return this.httpGet("search", this.resolveRequestParams(data));
    }
}

export const searchService = resolveService(SearchTopicHttpService);

export class OrganizationHttpService extends HttpService {
    constructor() {
        super("organization");
    }

    getByCode(data: any) {
        return this.httpGet("parkcatalog/by-code/" + data);
    }
}

export const organizationService = resolveService(OrganizationHttpService);
